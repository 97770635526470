import {
  btnLink,
  twitterColor,
  dribbbleColor,
  instagramColor,
  grayColor,
  main,
  mainRaised,
  container,
  title,
  whiteColor,
} from 'assets/jss/styles.js'

const layoutPageStyle = {
  container: {
    color: grayColor[1],
    ...container,
    zIndex: '2',
    padding: '70px 0 0',
  },
  bigMap: {
    height: "55vh",
    maxHeight: "550px",
    width: "100%",
    display: "block",
    "& .gm-style .place-card-large": {
      position: "absolute",
      backgroundColor: whiteColor,
      top: "100px"
    }
  },
  main: {
    ...main,
    display: 'flex',
  },
  mainRaised: {
    ...mainRaised,
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: whiteColor,
    textDecoration: 'none',
  },
  block: {
    color: 'inherit',
    padding: '0.9375rem',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    padding: '15px 0',
    margin: '0',
    float: 'right',
  },
  icon: {
    width: '18px',
    height: '18px',
    top: '3px',
    position: 'relative',
  },
  //Footer
  img: {
    width: '20%',
    marginRight: '5%',
    marginBottom: '5%',
    float: 'left',
  },
  aClass: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  footerBrand: {
    height: '50px',
    padding: '15px 15px',
    fontSize: '18px',
    lineHeight: '50px',
    marginLeft: '-15px',
    color: grayColor[1],
    textDecoration: 'none',
    fontWeight: 700,
    fontFamily: 'Roboto Slab,Times New Roman,serif',
  },
  pullCenter: {
    display: 'inline-block',
    float: 'none',
  },
  rightLinks: {
    float: 'right!important',
    '& ul': {
      marginBottom: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'inline-block',
      },
      '& a': {
        display: 'block',
      },
    },
    '& i': {
      fontSize: '20px',
    },
  },
  linksVertical: {
    '& li': {
      display: 'block !important',
      marginLeft: '-5px',
      marginRight: '-5px',
      '& a': {
        padding: '15px 5px !important',
      },
    },
  },
  footer: {
    '& ul li': {
      display: 'inline-block',
    },
    '& h4, & h5': {
      color: grayColor[1],
      textDecoration: 'none',
    },
    '& ul:not($socialButtons) li a': {
      color: 'inherit',
      padding: '0.9375rem',
      fontWeight: '500',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '3px',
      textDecoration: 'none',
      position: 'relative',
      display: 'block',
    },
    '& small': {
      fontSize: '75%',
      color: grayColor[10],
    },
    '& $pullCenter': {
      display: 'inline-block',
      float: 'none',
    },
  },
  iconSocial: {
    width: '41px',
    height: '41px',
    fontSize: '24px',
    minWidth: '41px',
    padding: 0,
    overflow: 'hidden',
    position: 'relative',
  },
  copyRight: {
    padding: '15px 0px',
  },
  socialButtons: {
    '& li': {
      display: 'inline-block',
    },
  },
  btnTwitter: {
    ...btnLink,
    color: twitterColor,
  },
  btnDribbble: {
    ...btnLink,
    color: dribbbleColor,
  },
  btnInstagram: {
    ...btnLink,
    color: instagramColor,
  },
  customFormControl: {
    paddingTop: '14px',
  },
  logo: {
    marginBottom: "30px"
  }
}

export default layoutPageStyle
